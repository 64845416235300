import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { createRoot, hydrateRoot } from 'react-dom/client';
import 'react-dropzone-uploader/dist/styles.css';
import 'swagger-ui-react/swagger-ui.css';
import AppContainer from './AppContainer';
import AppConfig from './config/AppConfig';
import * as serviceWorker from './serviceWorker';
import './styles/ag-grid-styles.css';
import './styles/main.css';
import './styles/rechart-styles.css';
import './styles/szh-menu-styles.css';

// Unregister service worker
serviceWorker.unregister();

LicenseManager.setLicenseKey(AppConfig.agGridLicenseKey);

const mountEl: HTMLElement | null = document.getElementById('root');
const root = createRoot(mountEl!);

if (mountEl?.hasChildNodes() === true) {
  hydrateRoot(mountEl, <AppContainer />);
} else {
  root.render(<AppContainer />);
}

if (module.hot) {
  module.hot.accept('./AppContainer', () => {
    root.render(<AppContainer />);
  });
}
