import { DataPickerStatus } from 'modules/GlobalReducer';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';

import { Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { format } from 'date-fns';

const CustomToolbar = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0px 24px',
    '& 	.MuiTypography-root': {
      color: '#eee',
    },
  },
}))(Toolbar);

const DatePickerToolbar = ({
  date,
  setDataPickerStatus,
  showClearButton = true,
}: {
  setDataPickerStatus: (status: DataPickerStatus) => void;
  date: any;
  showClearButton?: boolean;
}) => {
  const formatDate =
    date instanceof Date
      ? format(date, 'EEE, LLL dd')
      : format(
          !isNaN(Date.parse(date)) ? new Date(date as Date) : new Date(),
          'EEE, LLL dd',
        );

  const year = (date ? new Date(date as Date) : new Date()).getFullYear();

  return (
    <>
      <CustomToolbar>
        <Typography variant="h6">{year}</Typography>
        <Typography variant="h4">{formatDate}</Typography>
      </CustomToolbar>

      <Wrapper>
        {showClearButton ? (
          <ActionButton
            onClick={() => setDataPickerStatus(DataPickerStatus.clear)}
          >
            Clear
          </ActionButton>
        ) : null}
        <ActionButton
          onClick={() => setDataPickerStatus(DataPickerStatus.canceled)}
        >
          Cancel
        </ActionButton>
        <ActionButton onClick={() => setDataPickerStatus(DataPickerStatus.ok)}>
          OK
        </ActionButton>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  order: 3;
  align-self: flex-end;
  margin: 5px 10px 10px 0;
`;

const ActionButton = styled.button`
  width: fit-content;
  border: none;
  background: #fff;
  font-weight: 600;
  color: ${colors.primary};
  cursor: pointer;
  z-index: 5;
  padding: 10px;
  transition: all 0.5s ease;
  :hover {
    background: rgba(67, 63, 181, 0.04);
  }
  &:first-child {
    margin: 0 auto 0 20px;
  }
`;

export default DatePickerToolbar;
