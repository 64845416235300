import { types } from '../constants/constants';

export function processFeedback(feedbackData: Record<string, any>) {
  const directivesRowData = feedbackData.feedback
    ?.filter(
      // filter out global and NA directives
      ({ _directive_type }: { _directive_type: string }) =>
        _directive_type !== 'global' &&
        _directive_type !== 'NA' &&
        _directive_type !== 'Total' &&
        _directive_type,
    )
    ?.map((item: any, index: number) => {
      // create a row for each directive
      const row: any = {
        index: index + 1,
        directive_id: item._directive_number,
        directive_type: item._directive_type,
        table_rows_read: item.attempted_records,
        measurement_cells_read: item.attempted_measurements,
        n_created: item.n_created,
        n_updated: item.n_updated,
        n_redundant: item.n_redundant,
        n_failed: item.n_failed,
      };

      // add summary columns
      types.forEach((type: string) => {
        row[`${type}_summary`] = feedbackData.allow_updates
          ? `${(item as any)[`n_${type}_created`]} - ${
              (item as any)[`n_${type}_updated`]
            } - ${(item as any)[`n_${type}_redundant`]} - ${
              (item as any)[`n_${type}_failed`]
            }`
          : `${(item as any)[`n_${type}_created`]} - ${
              (item as any)[`n_${type}_redundant`]
            } - ${(item as any)[`n_${type}_failed`]}`;
      });

      return row;
    });

  let index = 1;

  const messagesRowData = feedbackData.feedback
    ?.filter(
      // filter out Total directives
      ({ _directive_type }: { _directive_type: string }) =>
        _directive_type !== 'Total' && _directive_type,
    )
    ?.map((item: any) => {
      return Object.entries(item.all_events)
        .flatMap(([key, value]) => {
          const [message_type, outcome, warning_error] = key.split('|');
          const directive_id = item._directive_number;
          const secondaryKey = `${message_type.toLowerCase()}s_${outcome}_by_type`;
          const typesWithValues = Object.entries(
            (item as any)[secondaryKey] ?? {},
          );

          return typesWithValues.length > 0
            ? typesWithValues.map(([type, typeValue]) => ({
                index: index++,
                directive_id,
                message_type,
                outcome,
                message: warning_error ? warning_error : '',
                types: type,
                count: typeValue,
              }))
            : {
                index: index++,
                directive_id,
                message_type,
                outcome,
                message: warning_error ? warning_error : '',
                types: '',
                count: value,
              };
        })
        .filter((row) => row !== null);
    })
    ?.flat();

  return { directivesRowData, messagesRowData };
}
