import * as R from 'ramda';

import { RowNode } from 'ag-grid-community';
import { capitalizedFirstLetter } from 'utils/utils';
import { StateKey } from '../utils/enum';
import {
  AgGridApi,
  ContextMenuData,
  STATE_KEY as GLOBAL_STATE_KEY,
  ModalEventData,
  QueueModalInfoType,
  StackModalDataType,
} from './GlobalReducer';

const modelDefinitionPath = [GLOBAL_STATE_KEY, 'modelDefinitions', 'data'];

export const getDrawerData = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'drawer'], state);

export const getModalEventData = (state: Record<string, any>): ModalEventData =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'modalEventData'], state);

export const getModelDefinitions = (
  state: Record<string, any>,
  modelApi: string,
) =>
  R.pathOr(
    [],
    [...modelDefinitionPath, capitalizedFirstLetter(modelApi)],
    state,
  );

export const getModelItem = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'modelItem'], state);

export const getCancelCheck = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'cancelCheck'], state);
export const getCancelMultiple = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'cancelMultiple'], state);

export const getReactivateMultiple = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'reactivateMultiple'], state);

export const getMpmFileIds = (state: Record<string, any>) =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'mpmFileIds'], state);

export const getStackModalData = (
  state: Record<string, any>,
): StackModalDataType =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'stackModalData'], state);

export const getQueueModalInfo = (
  state: Record<string, any>,
): QueueModalInfoType =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'queueModalInfo'], state);

export const getSelectedNodes = (
  state: Record<string, any>,
  modelName: string,
): RowNode[] =>
  R.pathOr(
    '',
    [StateKey[modelName], StateKey[modelName], 'selectedNodes'],
    state,
  );

export const getContextMenuData = (
  state: Record<string, any>,
): ContextMenuData =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'contextMenuData'], state);

export const getAgGridApi = (state: Record<string, any>): AgGridApi =>
  R.pathOr('', [GLOBAL_STATE_KEY, 'agGridApi'], state);
