import { ColumnApi, GridApi } from 'ag-grid-community';
import * as R from 'ramda';
import { EventName, ModelName, QueueModalType } from 'utils/enum';

import * as actionTypes from './GlobalActionsTypes';
import { enrichWMEAModelDefinitions } from './measurements/utils';

export const STATE_KEY = 'global';

export enum DataPickerStatus {
  closed = 'closed',
  clear = 'clear',
  canceled = 'canceled',
  open = 'open',
  ok = 'ok',
}

type GlobalType = {
  data: Record<string, any> | '';
  loading: boolean;
  error: boolean | string;
};

type Drawer = {
  state: boolean;
  width: number;
};

export enum ModalAction {
  Create = 'create',
  View = 'view',
  Edit = 'edit',
  QueueEdit = 'queueEdit',
}

export enum ModalEventAction {
  Inactivate = 'inactivate',
  Reactivate = 'reactivate',
}

export type ModalEventData = {
  open: boolean;
  eventName: EventName | '';
  modelName: ModelName | '';
  ids: string[] | number[];
  fromModel?: ModelName | string;
  gridApi?: GridApi | null;
};

type MpmFileIdsType = {
  loading: boolean;
  data: {
    count: number;
    file_ids: string[] | number[];
  };
  downloadFinished: boolean;
  error: boolean | string;
};

export type QueueModalInfoType = {
  showModal: boolean;
  processingError: string;
  modalType: QueueModalType | null;
  modelName: ModelName | null;
  afterCreating?: boolean;
  isSendingProcessingRequest?: boolean;
};

export type StackModalDataType = {
  loading: boolean;
  error: string;
  showModal: boolean;
  modelName: ModelName | '';
  gridApi: GridApi | null;
  columnApi: ColumnApi | null;
  isLastStackModal: boolean;
  currentAction: ModalAction | null;
  isModalOpenByQuery?: boolean;
  modalInfo: {
    modelName: ModelName;
    currentModelIds: number[];
    modalData: Record<string, any>;
    action: ModalAction;
    isMultiple?: boolean;
    isFromBlockBFD?: boolean;
  }[];
};

export type ContextMenuData = {
  loading: boolean;
  error: string;
  data: Record<string, any>[];
};

export type AgGridApi = {
  gridApi: GridApi;
  columnApi: ColumnApi;
};

export type InitialGlobalState = {
  drawer: Drawer;
  modalEventData: ModalEventData;
  modelDefinitions: GlobalType;
  choices: GlobalType;
  modelItem: GlobalType;
  view: GlobalType;
  cancelCheck: GlobalType;
  cancelMultiple: GlobalType;
  reactivateMultiple: GlobalType;
  mpmFileIds: MpmFileIdsType;
  stackModalData: StackModalDataType;
  queueModalInfo: QueueModalInfoType;
  contextMenuData: ContextMenuData;
  agGridApi: AgGridApi;
};

const initialState: InitialGlobalState = {
  drawer: {
    state: false,
    width: 260,
  },
  agGridApi: {
    gridApi: null,
    columnApi: null,
  } as unknown as AgGridApi,
  stackModalData: {
    loading: false,
    error: '',
    showModal: false,
    modelName: '',
    gridApi: null,
    modalInfo: [],
    currentAction: null,
    isLastStackModal: true,
    isModalOpenByQuery: false,
    columnApi: null,
  },
  queueModalInfo: {
    showModal: false,
    processingError: '',
    modalType: null,
    modelName: null,
    afterCreating: false,
    isSendingProcessingRequest: false,
  },
  modalEventData: {
    open: false,
    eventName: '',
    modelName: '',
    ids: [],
    fromModel: '',
    gridApi: null,
  },
  modelDefinitions: {
    data: {},
    loading: false,
    error: false,
  },
  choices: {
    data: {},
    loading: false,
    error: false,
  },
  modelItem: {
    data: '',
    loading: false,
    error: false,
  },
  view: {
    loading: false,
    data: {},
    error: false,
  },
  cancelCheck: {
    loading: false,
    data: '',
    error: false,
  },
  cancelMultiple: {
    loading: false,
    data: {},
    error: false,
  },
  reactivateMultiple: {
    loading: false,
    data: {},
    error: false,
  },
  mpmFileIds: {
    loading: false,
    data: {
      count: 0,
      file_ids: [],
    },
    downloadFinished: false,
    error: false,
  },
  contextMenuData: {
    loading: false,
    data: [],
    error: '',
  },
};

const GlobalReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.OPEN_DRAWER: {
      return R.mergeDeepRight(state, { drawer: { state: true } });
    }
    case actionTypes.CLOSE_DRAWER: {
      return R.mergeDeepRight(state, { drawer: { state: false } });
    }

    case actionTypes.SET_MODAL_EVENT_DATA: {
      return R.mergeDeepRight(R.omit(['modalEventData'], state), {
        modalEventData: {
          ...R.omit(['gridApi'], state.modalEventData),
          gridApi: payload.gridApi,
          ...payload,
        },
      });
    }
    case actionTypes.CLEAR_MODAL_EVENT_DATA: {
      return R.mergeDeepRight(state, {
        modalEventData: initialState.modalEventData,
      });
    }

    case actionTypes.FETCH_STACK_MODAL_DATA_START: {
      return {
        ...state,
        stackModalData: {
          ...state.stackModalData,
          loading: true,
          showModal: true,
          ...payload,
        },
      };
    }
    case actionTypes.FETCH_STACK_MODAL_DATA_ERROR: {
      return {
        ...state,
        stackModalData: {
          ...state.stackModalData,
          loading: false,
          error: payload,
          isLastStackModal: state.stackModalData.modalInfo.length <= 1,
        },
      };
    }
    case actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS: {
      const isAfterEditModelItem = payload.afterEditModelItem;

      if (isAfterEditModelItem) {
        const queueModalInfo = {
          action: ModalAction.QueueEdit,
          currentModelIds: payload.modalInfo.currentModelIds,
          isFromBlockBFD: false,
          modalData: {},
          modelName: payload.modalInfo.modelName,
        };

        const modalInfo = [
          ...state.stackModalData.modalInfo.slice(0, -2),
          queueModalInfo,
          payload.modalInfo,
        ];

        return {
          ...state,
          stackModalData: {
            ...state.stackModalData,
            loading: false,
            error: '',
            modalInfo,
            isLastStackModal: modalInfo.length <= 1,
          },
        };
      }

      const modalInfo = [...state.stackModalData.modalInfo, payload.modalInfo];

      return {
        ...state,
        stackModalData: {
          ...state.stackModalData,
          loading: false,
          error: '',
          modalInfo,
          isLastStackModal: modalInfo.length <= 1,
        },
      };
    }
    case actionTypes.CLEAR_ALL_STACK_MODAL_DATA: {
      return {
        ...state,
        stackModalData: {
          ...initialState.stackModalData,
          gridApi: state.stackModalData.gridApi,
          columnApi: state.stackModalData.columnApi,
        },
      };
    }
    case actionTypes.CLEAR_STACK_MODAL_DATA: {
      const prevModalInfo = state.stackModalData.modalInfo.slice(
        0,
        -payload.deep,
      );
      const currentModalInfo =
        state.stackModalData.modalInfo.length > payload.deep
          ? prevModalInfo.slice(-1)[0]
          : state.stackModalData.modalInfo.slice(0, -1)[0];

      return {
        ...state,
        stackModalData: {
          ...state.stackModalData,
          error: '',
          modalInfo: [...prevModalInfo],
          currentAction: currentModalInfo?.action,
          modelName: currentModalInfo?.modelName,
          showModal: !state.stackModalData.isLastStackModal,
          isLastStackModal: prevModalInfo.length <= 1,
        },
      };
    }
    case actionTypes.SET_GRID_API_TO_STACK_MODAL_DATA: {
      return {
        ...state,
        stackModalData: {
          ...state.stackModalData,
          gridApi: payload.gridApi,
          columnApi: payload.columnApi,
        },
      };
    }

    case actionTypes.UPDATE_QUEUE_MODAL_STATE: {
      return R.mergeDeepRight(state, {
        queueModalInfo: payload,
      });
    }
    case actionTypes.CLEAR_QUEUE_MODAL_STATE: {
      return R.mergeDeepRight(state, {
        queueModalInfo: initialState.queueModalInfo,
      });
    }

    case actionTypes.MODEL_DEFINITIONS_START: {
      return R.mergeDeepRight(state, { modelDefinitions: { loading: true } });
    }
    case actionTypes.MODEL_DEFINITIONS_SUCCESS: {
      const enrichedModelDefinitions = enrichWMEAModelDefinitions(payload);

      return R.mergeDeepRight(state, {
        modelDefinitions: {
          data: enrichedModelDefinitions,
          loading: false,
        },
      });
    }
    case actionTypes.MODEL_DEFINITIONS_ERROR: {
      return R.mergeDeepRight(state, { modelDefinitions: { error: payload } });
    }

    case actionTypes.CREATE_MODEL_ITEM_START:
    case actionTypes.EDIT_MODEL_ITEM_START:
    case actionTypes.EDIT_MODEL_MULTIPLE_ITEM_START: {
      return R.mergeDeepRight(state, { modelItem: { loading: true } });
    }
    case actionTypes.CREATE_MODEL_ITEM_SUCCESS:
    case actionTypes.EDIT_MODEL_ITEM_SUCCESS: {
      return R.mergeDeepRight(state, {
        modelItem: {
          data: payload,
          loading: false,
        },
      });
    }
    case actionTypes.EDIT_MODEL_MULTIPLE_ITEM_SUCCESS: {
      return R.mergeDeepRight(state, {
        modelItem: {
          data: payload,
          loading: false,
        },
      });
    }
    case actionTypes.CREATE_MODEL_ITEM_ERROR:
    case actionTypes.EDIT_MODEL_ITEM_ERROR:
    case actionTypes.EDIT_MODEL_MULTIPLE_ITEM_ERROR: {
      return R.mergeDeepRight(state, {
        modelItem: { error: payload, loading: false },
      });
    }

    case actionTypes.CLEAR_STATE_MODEL_ITEM: {
      return R.mergeDeepRight(state, {
        modelItem: {
          data: '',
          loading: false,
          error: false,
        },
      });
    }

    case actionTypes.CANCEL_CHECK_START: {
      return {
        ...state,
        cancelCheck: {
          data: {},
          loading: true,
          error: false,
        },
      };
    }
    case actionTypes.CANCEL_CHECK_SUCCESS: {
      return {
        ...state,
        cancelCheck: {
          data: payload,
          loading: false,
          error: false,
        },
      };
    }
    case actionTypes.CANCEL_CHECK_ERROR: {
      return {
        ...state,
        cancelCheck: {
          data: {},
          loading: false,
          error: payload,
        },
      };
    }

    case actionTypes.FETCH_CONTEXT_MENU_DATA_START: {
      return {
        ...state,
        contextMenuData: {
          data: [],
          loading: true,
          error: '',
        },
      };
    }
    case actionTypes.FETCH_CONTEXT_MENU_DATA_SUCCESS: {
      return {
        ...state,
        contextMenuData: {
          data: payload,
          loading: false,
          error: '',
        },
      };
    }
    case actionTypes.FETCH_CONTEXT_MENU_DATA_ERROR: {
      return {
        ...state,
        contextMenuData: {
          data: [],
          loading: false,
          error: payload,
        },
      };
    }
    case actionTypes.CLEAR_CONTEXT_MENU_DATA: {
      return {
        ...state,
        contextMenuData: {
          data: [],
          loading: false,
          error: '',
        },
      };
    }

    case actionTypes.CANCEL_MULTIPLE_START: {
      return {
        ...state,
        cancelMultiple: {
          data: {},
          loading: true,
          error: false,
        },
      };
    }
    case actionTypes.CANCEL_MULTIPLE_SUCCESS: {
      return {
        ...state,
        cancelMultiple: {
          data: payload,
          loading: false,
          error: false,
        },
      };
    }
    case actionTypes.CANCEL_MULTIPLE_ERROR: {
      return {
        ...state,
        cancelMultiple: {
          data: {},
          loading: false,
          error: payload,
        },
      };
    }

    case actionTypes.CLEAR_CANCEL_STATE: {
      return {
        ...state,
        cancelCheck: {
          data: '',
          loading: false,
          error: false,
        },
        cancelMultiple: {
          data: {},
          loading: false,
          error: false,
        },
      };
    }

    case actionTypes.REACTIVATE_MULTIPLE_START: {
      return {
        ...state,
        reactivateMultiple: {
          data: {},
          loading: true,
          error: false,
        },
      };
    }
    case actionTypes.REACTIVATE_MULTIPLE_SUCCESS: {
      return {
        ...state,
        reactivateMultiple: {
          data: payload,
          loading: false,
          error: false,
        },
      };
    }
    case actionTypes.REACTIVATE_MULTIPLE_ERROR: {
      return {
        ...state,
        reactivateMultiple: {
          data: {},
          loading: false,
          error: payload,
        },
      };
    }
    case actionTypes.CLEAR_REACTIVATE_STATE: {
      return {
        ...state,
        reactivateMultiple: {
          data: {},
          loading: false,
          error: false,
        },
      };
    }

    case actionTypes.FETCH_MPM_FILE_IDS_START: {
      return {
        ...state,
        mpmFileIds: {
          data: {},
          loading: true,
          error: false,
        },
      };
    }
    case actionTypes.FETCH_MPM_FILE_IDS_SUCCESS: {
      return {
        ...state,
        mpmFileIds: {
          data: payload,
          loading: false,
          error: false,
        },
      };
    }
    case actionTypes.FETCH_MPM_FILE_IDS_ERROR: {
      return {
        ...state,
        mpmFileIds: {
          data: {},
          loading: false,
          error: payload,
        },
      };
    }
    case actionTypes.DOWNLOAD_ORIGINAL_FILES_FINISHED: {
      return {
        ...state,
        mpmFileIds: {
          data: {},
          loading: false,
          error: false,
          downloadFinished: true,
        },
      };
    }
    case actionTypes.CLEAR_DOWNLOAD_ORIGINAL_FILE_STATE: {
      return {
        ...state,
        mpmFileIds: {
          data: {
            count: 0,
            file_ids: [],
          },
          loading: false,
          error: false,
          downloadFinished: false,
        },
      };
    }

    case actionTypes.SET_AG_GRID_API: {
      return {
        ...state,
        agGridApi: payload,
      };
    }

    default:
      return state;
  }
};

export default GlobalReducer;
