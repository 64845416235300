import {
  ColumnApi,
  GridApi,
  IRowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { Dispatch } from 'redux';

import { AppDispatch } from 'hooks/reduxHooks';
import {
  setSelectedNodes,
  setTableFilter,
  setTableSort,
} from 'modules/GlobalActions';
import { setExploreTableSelectedNodes } from 'modules/explore/ExploreActions';
import { TableOptionsType } from 'modules/related';
import { setRelatedTableSelectedNodes } from 'modules/related/RelatedActions';
import { NavigateFunction } from 'react-router-dom';
import { tableExportAgGrid, updateUrlSearchByFilterModel } from 'utils/agGrid';
import { ApiModelName, ExploreTable, ModelName } from 'utils/enum';
import { tableExportHttp } from 'utils/utils';
import { AG_GRID_TABLE_TYPE } from '../interfaces/tables';

export const handleClickExportItem = (
  menuData: Record<string, any>,
  gridApi: GridApi | null,
  pathname: string,
  dispatch: AppDispatch,
  columnApi: ColumnApi | null,
  modelApi: string,
  modelName: string,
  relatedOptions?: {
    sourceModel: ModelName;
    fetchCanceled: boolean;
    idsFromParam: number[];
    tableOptions: TableOptionsType;
  },
  exporeOptions?: {
    material_db_ids: number[];
    process_db_ids: number[];
  },
) => {
  if (menuData.onlySelected) {
    tableExportAgGrid(menuData, gridApi, pathname, modelApi as ExploreTable);
  } else {
    if (gridApi && columnApi) {
      tableExportHttp(
        dispatch,
        gridApi,
        columnApi,
        modelApi,
        modelName,
        pathname,
        menuData?.name,
        menuData?.format,
        relatedOptions,
        exporeOptions,
      );
    }
  }
};

export const handleRowClicked = (
  event: RowSelectedEvent,
  isOpenContextMenu: React.MutableRefObject<boolean>,
  gridApi: GridApi | null,
) => {
  const isClickedOnCheckbox = (event.event?.target as any)?.innerHTML?.includes(
    'checkbox-selection-cell',
  );

  if (isClickedOnCheckbox) {
    gridApi?.clearRangeSelection();
  }

  const areCellsSelected =
    (gridApi as any)?.rangeService?.cellRanges?.[0]?.columns?.length > 1;

  if (!areCellsSelected) {
    if (!isOpenContextMenu.current) {
      event.node.isSelected()
        ? event.node.setSelected(false, false)
        : event.node.setSelected(true);
    }
    isOpenContextMenu.current = false;
  }
};

export const handleSelectionChanged = (
  { api }: { api: GridApi },
  setter: React.Dispatch<Array<IRowNode>>,
  dispatch: Dispatch,
  modelName?: string,
) => {
  api.clearRangeSelection();
  setter(api.getSelectedNodes());
  dispatch &&
    modelName &&
    dispatch(setSelectedNodes(modelName, api.getSelectedNodes()));
};

export const handleExploreTableSelectionChanged = (
  { api }: { api: GridApi },
  setter: React.Dispatch<Array<IRowNode>>,
  dispatch: Dispatch,
  modelApi: ExploreTable,
) => {
  api.clearRangeSelection();
  setter(api.getSelectedNodes());
  dispatch &&
    modelApi &&
    dispatch(setExploreTableSelectedNodes(modelApi, api.getSelectedNodes()));
};

export const handleRelatedTableSelectionChanged = (
  { api }: { api: GridApi },
  setter: React.Dispatch<React.SetStateAction<IRowNode[]>>,
  dispatch: Dispatch,
  destModel: ModelName | ApiModelName,
) => {
  api.clearRangeSelection();
  setter(api.getSelectedNodes());
  dispatch &&
    destModel &&
    dispatch(setRelatedTableSelectedNodes(destModel, api.getSelectedNodes()));
};

export const handleFilterChanged = ({
  api,
  dispatch,
  modelName,
  setSearchParams,
  shouldSetFilter = true,
  showCancel,
  tableType = AG_GRID_TABLE_TYPE.SEARCH,
  multTable,
}: {
  api: GridApi;
  dispatch: Dispatch;
  modelName: ModelName;
  setSearchParams: any;
  showCancel: boolean;
  shouldSetFilter?: boolean;
  tableType?: AG_GRID_TABLE_TYPE;
  multTable?: {
    search: string;
    curTableName: string;
    navigate: NavigateFunction;
  };
}) => {
  const filter = api.getFilterModel();
  if (Object.keys(filter).length === 0 && setSearchParams) {
    if (multTable) {
      const regex = new RegExp(
        `(_table=${multTable.curTableName}(?:&(?!_table)[^&]+)*)`,
      );
      const newSearchForThisTable = tableType
        ? `_table=${multTable.curTableName}&_active=${modelName}`
        : `_table=${multTable.curTableName}`;
      const searchParams = multTable.search.replace(
        regex,
        newSearchForThisTable,
      );
      multTable.navigate(searchParams, { replace: true });
    } else {
      setSearchParams((params: any) => {
        params.set('voided', showCancel ? '1' : '0');

        return params;
      });
    }
  }

  updateUrlSearchByFilterModel(
    filter,
    showCancel,
    tableType,
    modelName,
    multTable,
  );

  if (shouldSetFilter) {
    dispatch(setTableFilter(modelName, filter));
  }
};

export const handleSortChanged = (
  { columnApi }: { columnApi: ColumnApi },
  dispatch: Dispatch,
  modelName: string,
) => {
  const sortModel = columnApi
    .getColumnState()
    .reduce((resModel: Array<any>, { sort, colId }: any) => {
      if (sort && colId) {
        resModel.push({ colId, sort });
      }
      return resModel;
    }, []);
  dispatch(setTableSort(modelName, sortModel));
};

export const handleFirstDataRendered = (
  { api }: { api: GridApi; columnApi: ColumnApi },
  filterModel: Record<string, any>[],
  sortModel: { colId: string; sort: 'asc' | 'desc' | null | undefined }[],
) => {
  api.setFilterModel(filterModel);

  if (sortModel) {
    api.applyColumnState({
      state: sortModel,
    });
  }
};

export const inSetPredicate = (filterValues: any[], cellValue: any) => {
  const cellValueStr = cellValue.toString();

  const isMatch = filterValues.some((filterValue) => {
    const splitValues = filterValue.split(',');
    return splitValues.includes(cellValueStr);
  });

  return isMatch;
};
