export enum ModelName {
  Material = 'material',
  Process = 'process',
  Measurement = 'measurement',
  Experiment = 'experiment',
  Program = 'program',
  Feedback = 'feedback',
  Message = 'message',
  Directive = 'directive',
  MaterialType = 'materialType',
  ProcessType = 'processType',
  MeasurementType = 'measurementType',
  ControlType = 'controlType',
  User = 'user',
  Team = 'team',
  Site = 'site',
  Instrument = 'instrument',
  Version = 'version',
  Branch = 'branch',
}

export enum ModelLabel {
  Materials = 'Materials',
  Processes = 'Processes',
  Measurements = 'Measurements',
  Experiments = 'Experiments',
  DataFiles = 'Data Files',
  MaterialTypes = 'Material Types',
  ProcessTypes = 'Process Types',
  MeasurementTypes = 'Measurement Types',
  ControlTypes = 'Control Types',
  Users = 'Users',
  Teams = 'Teams',
  Sites = 'Sites',
  Instruments = 'Instruments',
  Help = 'Help',
  Version = 'Version',
  Branch = 'Branch',
}

export enum ApiModelName {
  KMAT = 'KMAT',
  KPRO = 'KPRO',
  KMEA = 'KMEA',
  KEXP = 'KEXP',
  KFIL = 'KFIL',
  KMTT = 'KMTT',
  KPRT = 'KPRT',
  KMST = 'KMST',
  KCNT = 'KCNT',
  KUSR = 'KUSR',
  KTEA = 'KTEA',
  KSIT = 'KSIT',
  KINS = 'KINS',
  KBRA = 'KBRA',
  KFID = 'KFID',
  WMAT = 'WMAT',
  WPRO = 'WPRO',
  WMEA = 'WMEA',
  WEXP = 'WEXP',
  WFIL = 'WFIL',
  WMTT = 'WMTT',
  WPRT = 'WPRT',
  WMST = 'WMST',
  WCNT = 'WCNT',
  WUSR = 'WUSR',
  WTEA = 'WTEA',
  WSIT = 'WSIT',
  WINS = 'WINS',
  WBRA = 'WBRA',
  WFID = 'WFID',
}

export enum QueueModalType {
  Inactivate = 'inactivate',
  Reactivate = 'reactivate',
  Void = 'void',
  Edit = 'edit',
  Create = 'create',
}

export enum EventName {
  Inactivate = 'inactivate',
  Reactivate = 'reactivate',
  DownloadOriginalFile = 'downloadOriginalFile',
  DownloadCurrentFile = 'downloadCurrentFile',
}

export enum ExploreTable {
  KBRA = 'KBRA',
  KMAT = 'KMAT',
  KPRO = 'KPRO',
  KMEA = 'KMEA',
}

type ActionTypeProps = {
  [key: string]: string;
};

export const ActionType: ActionTypeProps = {
  material: 'MATERIALS',
  process: 'PROCESSES',
  measurement: 'MEASUREMENTS',
  experiment: 'EXPERIMENTS',
  program: 'FILES',
  feedback: 'FEEDBACK_DATA',
  materialType: 'MATERIAL_TYPES',
  processType: 'PROCESS_TYPES',
  measurementType: 'MEASUREMENT_TYPES',
  controlType: 'CONTROL_TYPES',
  user: 'USERS',
  team: 'TEAMS',
  site: 'SITES',
  instrument: 'INSTRUMENTS',
};

export const StateKey: ActionTypeProps = {
  material: 'materials',
  process: 'processes',
  measurement: 'measurements',
  experiment: 'experiments',
  program: 'files',
  feedback: 'feedback',
  materialType: 'materialTypes',
  processType: 'processTypes',
  measurementType: 'measurementTypes',
  controlType: 'controlTypes',
  user: 'users',
  team: 'teams',
  site: 'sites',
  instrument: 'instruments',
};

export const mapApiEndpoint: Record<ModelName, Array<string>> = {
  material: ['KMAT', 'WMAT'],
  process: ['KPRO', 'WPRO'],
  measurement: ['KMEA', 'WMEA'],
  program: ['KFIL', 'WFIL'],
  feedback: ['KFED', 'WFED'],
  site: ['KSIT', 'WSIT'],
  team: ['KTEA', 'WTEA'],
  user: ['KUSR', 'WUSR'],
  experiment: ['KEXP', 'WEXP'],
  instrument: ['KINS', 'WINS'],
  controlType: ['KCNT', 'WCNT'],
  materialType: ['KMTT', 'WMTT'],
  processType: ['KPRT', 'WPRT'],
  measurementType: ['KMST', 'WMST'],
  branch: ['KBRA', 'WBRA'],
  version: [],
  message: [],
  directive: [],
};

export const CONVERT_DEFINITION_TYPES: Record<string, string> = {
  int: 'number',
  str: 'string',
};

export const API_ENDPOINTS = {
  auth: {
    login: 'v1/auth/login',
    logout: 'v1/auth/logout',
    loginRefresh: 'v1/auth/login_refresh',
    pwchange: 'v1/auth/pwchange',
    pwforgot: 'v1/auth/pwforgot',
    pwforgotconfirm: 'v1/auth/pwforgotconfirm',
    user: 'v1/auth/user',
    organizations: 'v1/auth/organizations',
  },
  /**
   * Endpoint to fetch docs for swagger
   * @@path 'v1/specs'
   */
  specs: 'v1/specs',
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  explore: {
    /**
     * Need to specify /{model_name}
     * @@path 'v1/explore/table/{model_name}'
     */
    table: 'v1/explore/table',
    /**
     * Need to specify /{model_name}
     * @path 'v1/explore/bfd/{model_name}'
     */
    bfd: 'v1/explore/bfd',
  },
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  upload: {
    /**
     * @path 'v1/pfu/upload_urls'
     */
    uploadFile: 'v1/pfu/upload_urls',
    /**
     * Need to specify /{model_name}
     * @path 'v1/pfu/mpm_pfu_file_ids/{model_name}'
     */
    downloadMPM: 'v1/pfu/mpm_pfu_file_ids',
    /**
     * @path 'v1/pfu/download_urls'
     */
    download: 'v1/pfu/download_urls',
  },
  /**
   * Need to specify /{db_id}
   * @path 'v1/pfu/feedback/{db_id}'
   */
  feedback: 'v1/pfu/feedback',
  /**
   * CRUD endpoint groups
   */
  models: {
    /**
     * @method GET
     * @path 'v1/models/definitions'
     */
    getModelDefinitions: 'v1/models/definitions',
    /**
     * Need to specify /{model_name}
     * @method GET
     * @path 'v1/models/choices/{model_name}'
     */
    getChoices: 'v1/models/choices',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method GET
     * @path v1/models/choices/{model_name}/{db_id}
     */
    getEditChoices: 'v1/models/choices',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method GET
     * @path v1/models/view/{model_name}/{db_id}
     */
    getViewModel: 'v1/models/view',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/search/{model_name}
     */
    getTable: 'v1/models/search',
    /**
     * Need to specify /{src_name}/{dst_name}
     * @method POST
     * @path v1/models/related/{src_name}/{dst_name}
     */
    getRelatedTable: 'v1/models/related',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/single/{model_name}
     */
    createModelItem: 'v1/models/single',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/view/{model_name}
     */
    getMultipleViewModel: 'v1/models/view',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/delete_check/{model_name}
     */
    deleteCheck: 'v1/models/delete_check',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method PUT
     * @path v1/models/single/{model_name}/{db_id}
     */
    editModelItem: 'v1/models/single',
    /**
     * Need to specify /{model_name}
     * @method PUT
     * @path v1/models/bulk/{model_name}
     */
    editMultipleModelItem: 'v1/models/bulk',
    /**
     * Need to specify /{model_name}
     * @method PUT
     * @path v1/models/reactivate/{model_name}
     */
    reactivateMultiple: 'v1/models/reactivate',
    /**
     * Need to specify /{model_name}
     * @method DELETE
     * @path v1/models/bulk/{model_name}
     */
    cancelMultiple: 'v1/models/bulk',
  },
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  charts: {
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/chart/scatter/{model_name}
     */
    scatter: 'v1/chart/scatter',

    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/chart/histogram/{model_name}
     */
    histogram: 'v1/chart/histogram',
  },
};

export const FILTER_TYPES_FROM_PARAMS = {
  co: 'contains',
  nco: 'notContains',
  eq: 'equals',
  neq: 'notEqual',
  sw: 'startsWith',
  ew: 'endsWith',
  in: 'inSet',
  gt: 'greaterThan',
  ir: 'inRange',
  gte: 'greaterThanOrEqual',
  lte: 'lessThanOrEqual',
  lt: 'lessThan',
};
