import * as R from 'ramda';
import { API_ENDPOINTS } from 'utils/enum';

const apiUrl = R.defaultTo(
  'https://integration.api.r2d.io',
  process.env.REACT_APP_API_URL,
);

const AppConfig = {
  apiUrl,
  uploadUrl: `${apiUrl}/${API_ENDPOINTS.upload.uploadFile}`,
  agGridLicenseKey: R.defaultTo(
    null,
    `Using_this_{AG_Grid}_Enterprise_key_{AG-058317}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{R2DIO_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{R2DIO}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{R2DIO}_need_to_be_licensed___{R2DIO}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_June_2025}____[v3]_[01]_MTc0OTg1NTYwMDAwMA==99d0762d855a2d6f194531cf1d2bccd5`,
  ),
  supportUrl: 'https://support.r2dio.com',
  appEnv: R.defaultTo('staging', process.env.REACT_APP_ENV),
};

export default AppConfig;
