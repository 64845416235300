import { generateColumnDefs } from 'utils/agGrid';
import { ModelName } from 'utils/enum';

export const types = ['mat', 'pro', 'mea', 'par', 'inp'];

export const directivesModelDefinition = [
  ['index', 'int'],
  ['directive_id', 'int'],
  ['directive_type', 'str'],
  ['table_rows_read', 'int'],
  ['measurement_cells_read', 'int'],
  ...types.map((type) => [`${type}_summary`, 'str']),
  ['n_created', 'int'],
  ['n_updated', 'int'],
  ['n_redundant', 'int'],
  ['n_failed', 'int'],
];

export const messagesModelDefinition = [
  ['index', 'int'],
  ['directive_id', 'int'],
  ['message_type', 'str'],
  ['outcome', 'str'],
  ['message', 'str'],
  ['types', 'str'],
  ['count', 'int'],
];

export const columnDirectivesDefs = generateColumnDefs({
  modelDefinitions: directivesModelDefinition,
  modelName: ModelName.Directive,
});

export const columnMessagesDefs = generateColumnDefs({
  modelDefinitions: messagesModelDefinition,
  modelName: ModelName.Message,
});

export enum PanelName {
  Header = 'header',
  Directives = 'directives',
  Messages = 'messages',
}

export const PAGE_HEADER_HEIGHT = 44;
export const PAGE_ACTION_LINE_HEIGHT = 48;
export const TABLE_MARGIN = 5;
